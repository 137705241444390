.bottom_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 48px;

  .footer__bottom-text {
    font-size: 14px;
    color: #a3a3a3;
    margin-top: 24px;
  }

  .policy-nav {
    cursor: pointer;
  }

  .store_policies {
    @media screen and (min-width: 787px) {
      width: 800px;
      margin: 40px auto;
    }
    @media screen and (max-width: 786px) {
      flex-direction: column;
      align-items: center;
      margin: 0px auto 0;
    }
    .footer_nav {
      cursor: pointer;
    }
    .footer_nav:hover {
      text-decoration: underline;
    }
  }
}
